export class Post {
  constructor(){}
    itemid: string;
    isfeatured: boolean = false;
    setid: string;
    setname: string;
    title: string;
    imagelist: string;
    content: string;
  }
