Login
<div class="container">
    <div class="columns is-mobile is-centered">
      <div class="column is-half">
        <amplify-authenticator usernameAlias="username">
          <amplify-sign-in usernameAlias="username" [formFields]="formFields"></amplify-sign-in>
        </amplify-authenticator>
      </div>
    </div>
  </div>
  