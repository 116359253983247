import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { API, Auth } from 'aws-amplify';
import { Post } from '../types/index';

@Injectable({
  providedIn: 'root'
})
export class PostGatewayService {

  constructor(private authService: AuthService) { }

  async addPost(post: Post){
    const apiName = 'er4BlogAdm';
    const path = '/additem';

    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: post
    };

    API.post(apiName, path, myInit);
  }

  async getItem(itemid): Promise<any>{
    const apiName = 'er4BlogAdm';
    const path = '/getitem';
    let returnvalue: Post;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };

    await API.post(apiName, path, myInit)
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });      
    return returnvalue;
  }

  async publishItem(itemid): Promise<any>{
    const apiName = 'er4BlogAdm';
    const path = '/publishitem';
    let returnvalue: Post;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };

    await API.post(apiName, path, myInit)
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }

  async unpublishItem(itemid): Promise<any>{
    const apiName = 'er4BlogAdm';
    const path = '/unpublishitem';
    let returnvalue: Post;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };

    await API.post(apiName, path, myInit)
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }

  async removeItem(itemid): Promise<any>{
    const apiName = 'er4BlogAdm';
    const path = '/removeitem';
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };

    await API.post(apiName, path, myInit);
  }
}
