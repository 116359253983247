import { Component, OnInit } from '@angular/core';
import { PostGatewayService } from '../services/blog-gateway.service';
import { Post,PostItemId } from '../types/index';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  modelid = new PostItemId();
  model = new Post();

  constructor(private postGatewayService: PostGatewayService) { }

  ngOnInit(): void {
  }

  submitForm(){
    this.postGatewayService.addPost(this.model);
  }

  publishItem(){
    this.postGatewayService.publishItem(this.modelid);
  }

  unpublishItem(){
    this.postGatewayService.unpublishItem(this.modelid);
  }

  getItem(){
    this.postGatewayService.getItem(this.modelid).then(response => {
      this.model.itemid = response.itemid;
      this.model.setid = response.setid;
      this.model.setname = response.setname;
      this.model.title = response.title;
      this.model.content = JSON.parse(response.content);
      this.model.imagelist = response.imagelist;
      this.model.isfeatured = response.isfeatured;
    });
  }

  removeItem(){
    this.postGatewayService.removeItem(this.modelid);
  }
}
