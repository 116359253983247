
<div class="container">
    <h1>Post Form</h1>
    <form (ngSubmit)="getItem()" #flashcardFrom="ngForm">
      <div class="columns">
        <div class class="column is-2">
          <div class="form-group">
            <label class="label labelstyle">Item Id</label>
            <input type="text" class="form-control" id="itemid" required [(ngModel)]="modelid.itemid" name="itemid">
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-success">Get Post</button>
    </form>
    <br/>
    <br/>
    <form (ngSubmit)="submitForm()" #flashcardFrom="ngForm">
      <div class="columns">
        <div class="column is-3">
          <div class="form-group">
            <label class="label labelstyle">Item Id</label>
            <input type="text" class="form-control" id="itemid" required [(ngModel)]="model.itemid" name="itemid">
          </div>
        </div>
        <div class="column is-3">
          <div class="form-group">
            <label class="label labelstyle">Set Id</label>
            <input type="text" class="form-control" id="setid" required [(ngModel)]="model.setid" name="setid">
          </div>
        </div>
        <div class="column is-4">
          <div class="form-group">
            <label class="label labelstyle">Set Name</label>
            <input type="text" class="form-control" id="setname" required [(ngModel)]="model.setname" name="setname">
          </div>
        </div>
        <div class="column is-1">
          <div class="form-group">
            <label class="label labelstyle">Is Featured</label>
            <input type="checkbox" class="form-control checkstyle" id="idfeatured" required [(ngModel)]="model.isfeatured" name="isfeatured">
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="label labelstyle">Title</label>
        <input type="text" class="form-control" id="title" required [(ngModel)]="model.title" name="title">
      </div>
      <div class="form-group">
        <label class="label labelstyle">Image List</label>
        <br>
        <label class="label labelstyle">NO Spaces!</label>
        <input type="text" class="form-control" id="imagelist" required [(ngModel)]="model.imagelist" name="imagelist">
      </div>
      <div class="form-group">
        <label class="label labelstyle">Post</label>
        <br>
        <label class="label labelstyle">![alt text](image.png "text")</label>
        <br>
        <label class="label labelstyle">[label](https://)</label>
        <textarea type="text" class="postbox form-control" id="content" required [(ngModel)]="model.content" name="content"></textarea>
      </div>
  
      <button type="submit" class="btn btn-success">Submit</button>
  
    </form>
    <br/>
    <br/>
    <br/>
    <br/>
  <form (ngSubmit)="publishItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input type="text" class="form-control" id="itemid" required [(ngModel)]="modelid.itemid" name="itemid">
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Publish Item</button>
  </form>
  <br/>
  <br/>
  <br/>
  <form (ngSubmit)="unpublishItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input type="text" class="form-control" id="itemid" required [(ngModel)]="modelid.itemid" name="itemid">
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Unpublish Item</button>
  </form>
  <br/>
  <br/>
  <br/>
  <form (ngSubmit)="removeItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input type="text" class="form-control" id="itemid" required [(ngModel)]="modelid.itemid" name="itemid">
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Remove Item</button>
  </form>
  <br/>
  <br/>
  <br/>
  </div>
  
  